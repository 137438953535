import {useState, useEffect, useRef, useMemo} from 'react'
import {useTranslation} from 'next-i18next'
import clsx from 'clsx'
import {useSelector, useDispatch} from 'react-redux'
import {useRouter} from 'next/router'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import AppRoundedButton from 'src/components/elements/buttons/AppRoundedButton'
import AppTypography from 'src/components/elements/typography/AppTypography'
import AppIcon from 'src/components/elements/icons/AppIcon'
import {useSmallSize} from 'src/hooks/screenSize'
import {getSelectedAmenities} from 'src/store/selectors/propertySearchSelectors'
import {
  fetchSearchData,
  setSearchPageNo,
  setSelectedAmenities,
} from 'src/store/actions/propertySearchActions'
import {DEFAULT_LOCALE} from 'src/constants/locale'
import {useSearchStyles} from 'src/components/modules/home/homeHero/styles'
import {popularAmenities} from 'src/constants/amenitiesDisplayList'
import {AmenityFeatureType} from 'src/types/amenities'
import {
  allAmenities,
  convertAmenitySlugsToAmenities,
} from 'src/utils/amenitiesData'
import AmenityFeatureRow from 'src/components/modules/listingStepOne/amenities/AmenityFeatureRow'
import Popover from '@material-ui/core/Popover'

export default function SearchAmenities(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const {t} = useTranslation('common')
  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})
  const amenities = useSelector(getSelectedAmenities)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        className={clsx(classes.flexWrapper, classes.searchButtonContainer)}
        style={{width: 192}}
        component={Button}
        onClick={handleClick}
        aria-controls="amenities_selection"
        aria-haspopup="true"
      >
        <Icon inTopBar={inTopBar} />
        <Grid item>
          <AppTypography
            variant="action"
            neutralColor={600}
            style={{lineHeight: 'normal'}}
          >
            {t('amenities')}
          </AppTypography>
          <SelectInfo length={amenities?.length} visible={!inTopBar} />
        </Grid>
      </Grid>
      <Popover
        id="amenities_selection"
        className={classes.amenitiesMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={2}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="w-full">
          <AmenitiesSection
            inTopBar={inTopBar}
            handleClose={handleClose}
            filteredSlugs={popularAmenities}
          />
        </div>
      </Popover>
    </>
  )
}

function Icon(props: {inTopBar?: boolean}) {
  const {inTopBar} = props

  const isSmallSize = useSmallSize()
  const classes = useSearchStyles({inTopBar, isSmallSize})

  if (inTopBar) {
    return null
  }

  return (
    <Grid item className={classes.searchButtonIcon}>
      <AppIcon name="amenities" />
    </Grid>
  )
}

function SelectInfo(props: {length: number; visible: boolean}) {
  const {length, visible} = props

  const {t} = useTranslation('common')
  const router = useRouter()
  const {locale} = router

  if (!visible) {
    return null
  }

  if (length === 0) {
    return (
      <AppTypography variant="action" neutralColor={500}>
        {t('amenities_description')}
      </AppTypography>
    )
  }

  if (length > 1 && locale === DEFAULT_LOCALE) {
    return (
      <AppTypography variant="action" neutralColor={500}>
        {`${length} ${t('selected')}s`}
      </AppTypography>
    )
  }

  return (
    <AppTypography
      variant="action"
      neutralColor={500}
      style={{lineHeight: 'normal'}}
    >
      {`${length} ${t('selected')}`}
    </AppTypography>
  )
}

export function AmenitiesSection(props: {
  inTopBar?: boolean
  handleClose?: () => void
  inMobileFilter?: boolean
  setAmenitiesSM?: React.Dispatch<string[]>
  filteredSlugs?: string[]
}) {
  const {handleClose, filteredSlugs, inMobileFilter, setAmenitiesSM} = props

  const isSmallSize = useSmallSize()
  const dispatch = useDispatch()
  const selectedAmenities = useSelector(getSelectedAmenities)
  const ref = useRef(null)
  const router = useRouter()

  const [localSelectedAmenities, setLocalSelectedAmenities] =
    useState<string[]>(selectedAmenities)

  useEffect(() => {
    setLocalSelectedAmenities(selectedAmenities)
  }, [selectedAmenities])

  const amenities = useMemo(() => {
    if (filteredSlugs) {
      return convertAmenitySlugsToAmenities(filteredSlugs)
    }
    return allAmenities
  }, [filteredSlugs])

  const handleAmenitySelection = (amenity: AmenityFeatureType) => {
    const newAmenities: string[] = [...localSelectedAmenities]
    let idx = newAmenities.indexOf(amenity.label)
    if (idx === -1) {
      newAmenities.push(amenity.label)
    } else {
      newAmenities.splice(idx, 1)
    }

    setLocalSelectedAmenities(newAmenities)

    if (isSmallSize) {
      dispatch(setSelectedAmenities(newAmenities))
    }

    if (setAmenitiesSM) {
      setAmenitiesSM(newAmenities)
    }
  }

  const handleCancel = () => {
    setLocalSelectedAmenities([])

    dispatch(setSelectedAmenities([]))
    if (setAmenitiesSM) {
      setAmenitiesSM([])
    }

    if (handleClose) {
      handleClose()
    }
  }

  const handleApply = () => {
    dispatch(setSelectedAmenities(localSelectedAmenities))
    dispatch(setSearchPageNo(1))

    dispatch(fetchSearchData(1, false, true, router))
    if (handleClose) {
      handleClose()
    }
  }

  return (
    <div ref={ref} id="amenities_selection">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 pb-12 md:py-4 md:px-6">
        {amenities.map((amenity: AmenityFeatureType) => {
          return (
            <div
              className="cursor-pointer flex flex-row flex-nowrap items-center space-x-3"
              onClick={() => handleAmenitySelection(amenity)}
              key={amenity.label}
            >
              <Checkbox
                checked={localSelectedAmenities.includes(amenity.label)}
                color="primary"
                classes={{
                  root: 'm-0 p-0',
                }}
                inputProps={{'aria-label': 'secondary checkbox'}}
              />
              <AmenityFeatureRow amenity={amenity} />
            </div>
          )
        })}
      </div>
      <Buttons
        visible={!inMobileFilter && !isSmallSize}
        handleCancel={handleCancel}
        handleApply={handleApply}
      />
    </div>
  )
}

function Buttons(props: {
  visible: boolean
  handleCancel: () => void
  handleApply: () => void
}) {
  const {visible, handleApply, handleCancel} = props

  const {t} = useTranslation('common')

  if (!visible) {
    return null
  }

  return (
    <div className="w-full flex flex-row justify-end items-start gap-4 p-4 mt-4 border-t border-divider">
      <AppRoundedButton variant="outlined" onClick={handleCancel}>
        {t('clear')}
      </AppRoundedButton>
      <AppRoundedButton
        variant="contained"
        color="primary"
        onClick={handleApply}
      >
        {t('apply')}
      </AppRoundedButton>
    </div>
  )
}
