import {forwardRef, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'next-i18next'
import {useSelector, useDispatch} from 'react-redux'
import clsx from 'clsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import AppTypography from 'src/components/elements/typography/AppTypography'

import {useBreakpoint, useSmallSize} from 'src/hooks/screenSize'
import {
  fetchSearchData,
  setCurrentMapZoom,
  setSearchPageNo,
  setSelectedLocation,
  setSortByFilter,
} from 'src/store/actions/propertySearchActions'
import {
  getSelectedGuests,
  getSelectedLocation,
} from 'src/store/selectors/propertySearchSelectors'
import {getSearchColumnLocation, getSearchColumns} from 'src/constants/property'
import {INITIAL_ZOOM_LEVEL} from 'src/constants/googleMap'
import {useStyles} from './styles'
import {publicGetLocationAutocompleteSearchApi} from 'src/services/api/location'
import axios, {CancelTokenSource} from 'axios'
import {AutocompleteLocationType} from 'src/types/location'
import {useDebounceFunc, useStateRef} from 'src/hooks/other'
import AppTextField from 'src/components/forms/textField/AppTextField'
import {stopPropagation, useKeyDown, useOutsideClick} from 'src/utils/dom'
import {LocationSearchType} from 'src/types/search'

const LocationSearchBox = forwardRef<
  HTMLInputElement,
  {
    inTopBar?: boolean
    inMobileView?: boolean
    onOpen?: (bool: boolean) => void
    defaultSearchValue?: string | null
  }
>((props, ref) => {
  const {inTopBar, inMobileView, onOpen, defaultSearchValue} = props

  const {t} = useTranslation('common')
  const isSmallSize = useSmallSize()
  const classes = useStyles({inTopBar, isSmallSize})
  const selectedLocation = useSelector(getSelectedLocation)
  const selectedGuests = useSelector(getSelectedGuests)

  const [status, setStatus] = useState<'standby' | 'loading' | 'loaded'>(
    'standby',
  )
  const [value, setValue] = useState<string>(
    selectedLocation && selectedLocation.address && !selectedLocation.automatic
      ? selectedLocation.address
      : '',
  )

  const comboboxRef = useRef<any | null>(null)
  const [suggestions, setSuggestions, suggestionsRef] = useStateRef<
    AutocompleteLocationType[]
  >([])
  const dispatch = useDispatch()
  const refer = useRef(null)
  const breakpoint = useBreakpoint()
  const fetchCancelSource = useRef<CancelTokenSource | null>(null)
  const [forceShowOptions, setForceShowOptions] = useState<boolean>(false)

  useOutsideClick(comboboxRef, () => {
    setForceShowOptions(false)
  })

  useKeyDown('Escape', () => {
    setForceShowOptions(false)
  })

  const fetchSuggestions = useDebounceFunc(
    (initSearch = false) => {
      if (!initSearch && status !== 'loading') {
        setStatus('loading')
      }
      if (suggestions.length !== 0) {
        setSuggestions([])
      }

      if (fetchCancelSource.current) {
        fetchCancelSource.current.cancel()
      }

      //set new token
      fetchCancelSource.current = axios.CancelToken.source()

      if (!value) {
        if (!initSearch) {
          setStatus('loaded')
        }
        return
      }

      publicGetLocationAutocompleteSearchApi(
        value,
        fetchCancelSource.current?.token,
      )
        .then((result) => {
          setSuggestions(result.results)
          if (!initSearch) {
            setStatus('loaded')
          }
        })
        .catch((err) => {
          //will be err cancel sometimes
          if (axios.isCancel(err)) {
            return
          }

          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    100,
    [value],
  )

  const handleInput = (e: any) => {
    const address = e.target.value
    setValue(address)

    if (address) {
      setForceShowOptions(true)
      if (onOpen) onOpen(true)
      setStatus('loading') //no debounce
      setSuggestions([]) //no debounce
      fetchSuggestions()
      return
    }

    if (onOpen) onOpen(false)
    //if address is empty
    setSuggestions([]) //clear suggestions
    dispatch(setSearchPageNo(1))
    dispatch(
      setSelectedLocation({
        address: null,
        automatic: false,
        autocompleteLocation: null,
        addressComps: {
          country: null,
          state: null,
          city: null,
          mrc: null,
          county: null,
        },
        coordinates: null,
      }),
    )
    dispatch(setCurrentMapZoom(INITIAL_ZOOM_LEVEL))
    setForceShowOptions(true)

    const adultCount = selectedGuests?.adults
      ? Number(selectedGuests.adults)
      : 0
    const childrenCount = selectedGuests?.children
      ? Number(selectedGuests.children)
      : 0

    const guestCount = adultCount + childrenCount
    if (guestCount > 0) {
      dispatch(setSortByFilter('relevance'))
    }

    dispatch(fetchSearchData(1, true))
  }

  const handleSelect = async (address: string, automatic: boolean) => {
    setValue(address)
    setForceShowOptions(false)
    if (onOpen) onOpen(false)

    const preDefinedValue: any = getSearchColumnLocation(address as string, t)

    if (preDefinedValue) {
      setSuggestions([])

      //todo remove try catch
      try {
        const options = breakpoint ? preDefinedValue.options[breakpoint] : null
        const location: LocationSearchType = {
          address,
          automatic: automatic,
          autocompleteLocation: null,
          addressComps: {
            country: null,
            state: null,
            city: null,
            mrc: null,
            county: null,
          },
          coordinates: {
            lat: options ? options[1] : null,
            lng: options ? options[2] : null,
          },
        }

        setValue(address)
        dispatch(setSelectedLocation(location))
        dispatch(setSortByFilter('distance_low_to_high'))
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('😱 Error: ', error)
      }
      return
    }

    //use suggestionsRef to the get the latest value possible, so react stuff dont get in the way
    const fromSuggestionLocation: AutocompleteLocationType | null =
      suggestionsRef.current.find((item) => item.title === address) || null

    const location: LocationSearchType = {
      address,
      automatic: automatic,
      autocompleteLocation: fromSuggestionLocation,
      addressComps: {
        country: null,
        state: null,
        city: null,
        mrc: null,
        county: null,
      },
      coordinates: {
        lat: fromSuggestionLocation ? fromSuggestionLocation.lat : null,
        lng: fromSuggestionLocation ? fromSuggestionLocation.lng : null,
      },
    }

    setValue(address)
    dispatch(setSelectedLocation(location))
    dispatch(setSortByFilter('distance_low_to_high'))
  }

  const getComboBoxInputClassName = () => {
    if (inTopBar) {
      return classes.headerSearchOptionText
    }

    if (inMobileView) {
      return classes.locationMobileInput
    }

    return classes.headerSearchOption
  }

  useEffect(() => {
    if (!defaultSearchValue) return

    publicGetLocationAutocompleteSearchApi(defaultSearchValue)
      .then(async (result) => {
        if (result.results.length > 0) {
          setSuggestions(result.results)
          //remove attraction and property so we select mrc, city, etc
          const bestResults = result.results.filter((r) => {
            return r.type !== 'attraction' && r.type !== 'property'
          })
          //use the best result or the first one
          handleSelect(
            bestResults.length > 0
              ? bestResults[0].title
              : result.results[0].title,
            true,
          )
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSearchValue])

  useEffect(() => {
    if (selectedLocation && selectedLocation.address) {
      if (value !== selectedLocation.address) {
        setValue(selectedLocation.address)
        setForceShowOptions(false)
        if (onOpen) onOpen(false)
        fetchSuggestions(true)
      }
      return
    }

    if (onOpen) onOpen(false)
    setValue('')
    setForceShowOptions(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation])

  return (
    <div
      className={classes.searchContainer}
      id="places_selection"
      ref={comboboxRef}
      onClick={() => {
        setForceShowOptions(true)
        if (onOpen) onOpen(true)
      }}
    >
      <div ref={refer}>
        {!inTopBar && !inMobileView && (
          <AppTypography
            variant="action"
            neutralColor={600}
            className={classes.locationLabel}
          >
            {t('location')}
          </AppTypography>
        )}
        <AppTextField
          value={value}
          onChange={handleInput}
          ref={ref}
          className={getComboBoxInputClassName()}
          placeholder={inTopBar ? t('location') : t('location_description')}
          id="places_input"
          name="location_description"
          style={{
            color: '#364259',
          }}
        />
        <Popover
          onSelect={(e) => handleSelect(e, false)}
          status={status}
          data={suggestions}
          searchValue={value}
          inTopBar={inTopBar}
          inMobileView={inMobileView}
          visible={forceShowOptions}
        />
      </div>
    </div>
  )
})

function Popover(props: {
  data: AutocompleteLocationType[]
  visible: boolean
  status: 'standby' | 'loading' | 'loaded'
  inTopBar?: boolean
  inMobileView?: boolean
  searchValue?: string
  onSelect: (text: string) => void
}) {
  const {visible, inTopBar, onSelect, inMobileView, data, status, searchValue} =
    props

  const isSmallSize = useSmallSize()
  const classes = useStyles({inTopBar, isSmallSize})

  const getComboBoxPopoverClassName = () => {
    if (isSmallSize && inMobileView) {
      return clsx(
        classes.locationResultsWrapperGeneral,
        classes.locationResultsWrapperMobile,
        classes.locationResultsWrapperSM,
      )
    }

    if (isSmallSize) {
      return clsx(
        classes.locationResultsWrapperGeneral,
        classes.locationResultsWrapper,
      )
    }

    if (inMobileView) {
      return clsx(
        classes.locationResultsWrapperGeneral,
        classes.locationResultsWrapper,
        classes.locationResultsWrapperSM,
      )
    }

    return clsx(
      classes.locationResultsWrapperGeneral,
      classes.locationResultsWrapper,
    )
  }

  if (!visible) {
    return null
  }

  return (
    <div className={getComboBoxPopoverClassName()}>
      <div className={classes.locationResultsList}>
        <Locations
          onSelect={onSelect}
          searchValue={searchValue}
          locations={data}
          inTopBar={inTopBar}
          status={status}
        />
      </div>
    </div>
  )
}

function Locations(props: {
  inTopBar?: boolean
  locations: AutocompleteLocationType[]
  status: 'standby' | 'loading' | 'loaded'
  searchValue?: string
  onSelect: (text: string) => void
}) {
  const {locations, onSelect, status, inTopBar = false, searchValue} = props

  const isSmallSize = useSmallSize()
  const classes = useStyles({inTopBar, isSmallSize})
  const {t} = useTranslation('common')

  if (locations.length === 0) {
    const searchColumnsData = getSearchColumns(t)
    const preDefinedLocation = searchValue
      ? getSearchColumnLocation(searchValue, t)
      : null

    return (
      <div>
        {status === 'loading' ? (
          <div className={classes.loader}>
            <CircularProgress size={isSmallSize ? 12 : 24} />
          </div>
        ) : (
          <>
            {searchValue && !preDefinedLocation && (
              <span className={classes.supPopularTitle}>
                {t('no_search_results', {search: searchValue})}
              </span>
            )}
          </>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-3">
          {searchColumnsData.map((column, index) => (
            <div key={index}>
              <span className={classes.popularTitle}>{column.title}</span>
              <div>
                {column.locations.map((location) => (
                  <Location
                    onClick={() => onSelect(location.label)}
                    inTopBar={inTopBar}
                    key={location.label}
                    description={location.label}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div>
      {status === 'loading' && (
        <div className={classes.loader}>
          <CircularProgress size={isSmallSize ? 12 : 24} />
        </div>
      )}
      {locations.map((location: AutocompleteLocationType, idx: number) => (
        <Location
          key={idx}
          description={location.title}
          onClick={() => onSelect(location.title)}
        />
      ))}
    </div>
  )
}

function Location(props: {
  inTopBar?: boolean
  description: string
  onClick: () => void
}) {
  const {inTopBar, description, onClick} = props

  const isSmallSize = useSmallSize()
  const classes = useStyles({inTopBar, isSmallSize})

  return (
    <div
      className={classes.locationResultsItem}
      onClick={stopPropagation(onClick)}
    >
      <AppTypography variant="action" className={classes.locationResultText}>
        {description}
      </AppTypography>
    </div>
  )
}

export default LocationSearchBox
